//@ts-nocheck
import * as React from 'react';
import styles from './Hero.module.scss';

const Hero = () => {
    return (
        <div className={styles.heroContainer}>
            <div className={styles.hero} />
        </div>
    );
}

export default Hero;