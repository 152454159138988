import * as React from 'react';
import styles from './GradientHeader.module.scss';
import { useApp } from '../state/app';

const GradientHeader: React.FC<{ section: string }> = ({ section }) => {
    const { getItem, setSelectedItem, isEditor } = useApp();
    const item = getItem(section, "section");
    if (!item) return null;
    const options = item.Options;
    return (
        <>
            <div 
                className={styles.header}
                style={{
                    background: `linear-gradient(90deg,${convertHexToRgba(options.gradient.start, 1)} 0%, ${convertHexToRgba(options.gradient.end, 1)} 100%)`
                }}
            >
                <h3>
                    {item.Title}
                </h3>
                {isEditor && <button onClick={() => setSelectedItem(item)} className={styles.editButton}>Edit</button>}
            </div>
            <p>{item.Description}</p>
        </>
    );
}

function convertHexToRgba(hex: string, opacity: number) {
    const hexValue = hex.replace('#', '');
    const r = parseInt(hexValue.substring(0, 2), 16);
    const g = parseInt(hexValue.substring(2, 4), 16);
    const b = parseInt(hexValue.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export default GradientHeader;