//@ts-nocheck
import * as React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import styles from './ImageGallery.module.scss';
import { useApp } from '../../state/app';
import EditGalleryForm from '../EditGalleryForm';

const ImageGallery = ({ name }: { name: string }) => {
    const { getItems, getItem, setEditGallery, isEditor } = useApp();
    const items = getItems(name) || [];
    const section = getItem(name, "section");
    if (!section) return null;

    const properties = {
        scale: 1.4,
        transitionDuration: 500,
        prevArrow: <button className={`${styles.arrow} ${styles.arrowPrev}`}><svg width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"></path></svg></button>,
        nextArrow: <button className={`${styles.arrow} ${styles.arrowNext}`}><svg width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path></svg></button>
    }

    return (
        <div key={`gallery_${items.length}`} className={styles.imageGallery}>
            <h2>{section.Title}</h2>
            <p dangerouslySetInnerHTML={{ __html: section.Description }} />
            {isEditor && <button className={styles.editButton} onClick={() => setEditGallery(true)}>Edit</button>}
            <div className={styles.container}>
                <Slide {...properties}>
                    {items.map(({ Image, Description }, index) => {
                        return (
                            <div key={`image_${index}`} className={styles.eachSlideEffect}>
                                <div style={{ 'backgroundImage': `url(${encodeURI(Image)})` }}>
                                    {/* <span>Slide 1</span> */}
                                </div>
                                {Description && <p>{Description}</p>}
                            </div>
                        )
                    })}
                </Slide>
            </div>
            <EditGalleryForm />
        </div>
    );
};

export default ImageGallery;