import type {
  IAvanadeHubSiteProps,
  ISPListItem,
} from '../types';

import icocalendar01 from '../assets/ico-calendar-01.svg';
import icograph01 from '../assets/ico-graph-01.svg';
import icogift01 from '../assets/ico-gift-01.svg';
import icolist01 from '../assets/ico-list-01.svg';
import icopeople01 from '../assets/ico-people-01.svg';
import icopencil01 from '../assets/ico-pencil-01.svg';
import icowebsite01 from '../assets/ico-website-01.svg';
import icobriefcase01 from '../assets/ico-briefcase-01.svg';
import icoglobe02 from '../assets/ico-globe-02.svg';
import icocheckmark01 from '../assets/ico-checkmark-01.svg';
import icopencil01a from '../assets/ico-pencil-01a.svg';
import icoheart01 from '../assets/ico-heart-01.svg';

export interface AppStoreProps {
  context: IAvanadeHubSiteProps;
}

interface AppStoreState {
  listGuid?: string;
  items?: ISPListItem[];
}

const eventCoEOptions = {
  iconColor: "#ffb414",
  gradient: { 'start': '#ff5800', 'end': '#ffb414' }
};
const inPersonOptions = {
  iconColor: "#ce0569",
  borderColor: "#ff5814",
  gradient: { 'start': '#ce0569', 'end': '#ff5814' }
};
const virtualOptions = {
  iconColor: "#c80000",
  borderColor: "#c80000",
  gradient: { 'start': '#960000', 'end': '#c80000' }
};
const moreResources = {
  iconColor: "#67005a"
};
const ourEvents = {};

const mockItems: Partial<ISPListItem>[] = [
  { ItemType: "section", Sort: "0", Section: "helpfulResources", Title: "Helpful Resources", Options: JSON.stringify(eventCoEOptions) },
  { ItemType: "item", Sort: "0", Section: 'helpfulResources', Title: "Global Calendar Events List", MenuIcon: icocalendar01, Link: 'https://avanade.sharepoint.com/sites/Marketing/SitePages/Global-Marketing-Calendar.aspx' },
  { ItemType: "item", Sort: "1", Section: 'helpfulResources', Title: "Power BI", MenuIcon: icograph01, Link: 'https://app.powerbi.com/groups/me/apps/de31d2e5-301f-4df8-aca0-10049d582c76/dashboards/569b1e66-19f7-4a01-aea5-403e4be0ad4a?experience=power-bi' },
  { ItemType: "item", Sort: "2", Section: 'helpfulResources', Title: "Gifts and Enertainment Policy", MenuIcon: icogift01, Link: "https://avanade.sharepoint.com/:p:/r/sites/GlobalEventsFY23-EventsCOE/Shared%20Documents/Events%20COE/CoE%20Documents%20(Lis%20working)/Avanade%20Event%20Policy%20Overview%20-%20FY23.pptx?d=w4e131ad814e44fbfaa856219a22bb323&csf=1&web=1&e=2vRkdZ" },
  { ItemType: "item", Sort: "3", Section: 'helpfulResources', Title: "Marketing Business Case Approval", MenuIcon: icolist01, Link: "https://avanade.sharepoint.com/sites/MarketingBusinessCaseandApprovalProcess" },
  { ItemType: "item", Sort: "4", Section: 'helpfulResources', Title: "Marketo", MenuIcon: icopeople01, Link: "https://avanade.sharepoint.com/:f:/r/teams/Marketing/Marketing%20PWS/Global%20Demand%20Center%20-%20Marketo%20Operations/Marketo?csf=1&web=1&e=3UzZmV" },
  { ItemType: "item", Sort: "5", Section: 'helpfulResources', Title: "Workfront", MenuIcon: icopencil01, Link: "https://avanade.my.workfront.com/login?nextURL=/myUpdates" },
  { ItemType: "item", Sort: "6", Section: 'helpfulResources', Title: "AMBG Internal Site", MenuIcon: icowebsite01, Link: "https://in.accenture.com/microsoft/" },
  { ItemType: "item", Sort: "7", Section: 'helpfulResources', Title: "AMBG External Site", MenuIcon: icobriefcase01, Link: "https://www.accenture.com/us-en/services/microsoft-index" },
  { ItemType: "section", Sort: "0", Section: "eventCoE", Title: "Event CoE", Description: "Welcome to the Global Events Center of Excellence! Discover the hub for all your event-related needs, bringing together a community of marketers to collaborate, develop ideas, and find everything needed to successfully execute events in their respective regions. Explore our toolkits for everything event-related from start to finish.", Options: JSON.stringify(eventCoEOptions) },
  { ItemType: "item", Sort: "0", Section: 'eventCoE', Title: "Event Strategy", Link: "https://avanade.sharepoint.com/:p:/r/sites/GlobalEventsFY23-EventsCOE/Shared%20Documents/Events%20COE/CoE%20Documents%20(Lis%20working)/To%20upload/Event%20Governance%20model%20FY24.pptx?d=w74a09f7d681d4103b7b69e9ca3f2830e&csf=1&web=1&e=2bxrYD", Icon: require('../assets/ico-chart-01.png') },
  { ItemType: "item", Sort: "1", Section: 'eventCoE', Title: "Partner & Alliance Management", Link: "https://avanade.sharepoint.com/:p:/s/GlobalEventsFY23-EventsCOE/EVJzzkjRBopAkLZaEKAejGoBimrCOzyoo4QpIutI8qHm2A?e=VcccG2", Icon: require('../assets/ico-handshake-01.png') },
  { ItemType: "item", Sort: "2", Section: 'eventCoE', Title: "Event Content", Link: "https://avanade.sharepoint.com/:p:/s/MarketingGlobalEventsCoE/EZuvqk5F3NVKvc3nuKdzk8MBv3IERfBmi0OyDMLVwQKOyw?e=ef6Pun", Icon: require('../assets/ico-card-01.png') },
  { ItemType: "item", Sort: "3", Section: 'eventCoE', Title: "Client Experience", Link: "#", Icon: require('../assets/ico-thumbsup-01.png') },
  { ItemType: "item", Sort: "4", Section: 'eventCoE', Title: "Event Logistics", Link: "https://avanade.sharepoint.com/:f:/s/GlobalEventsFY23-EventsCOE/EppGeQoyS3NBmil01cMmiTcBO4SvTP23RSQUi91fOjd0Jw?e=5Thn6B", Icon: require('../assets/ico-calendar-03.png') },
  { ItemType: "item", Sort: "5", Section: 'eventCoE', Title: "Digital Ops & Communications", Link: "https://avanade.sharepoint.com/:f:/s/GlobalEventsFY23-EventsCOE/EuVDe0sVb5FFgdkjdJ5JwLkBeWSsJe3fTKrXm5zj8DtQUg?e=dG7Ebn", Icon: require('../assets/ico-chat-01.png') },
  { ItemType: "item", Sort: "6", Section: 'eventCoE', Title: "Event Promotion - External", Link: "https://avanade.sharepoint.com/:f:/r/sites/GlobalEventsFY23-EventsCOE/Shared%20Documents/Events%20COE/CoE%20Documents%20(Lis%20working)/To%20upload/Event%20(OFT)%20Invitation%20Templates?csf=1&web=1&e=FIJulE", Icon: require('../assets/ico-present-01.png') },
  { ItemType: "item", Sort: "7", Section: 'eventCoE', Title: "Event Promotion - Internal", Link: "https://avanade.sharepoint.com/:f:/r/sites/GlobalEventsFY23-EventsCOE/Shared%20Documents/Events%20COE/CoE%20Documents%20(Lis%20working)/To%20upload/Internal%20Announcements?csf=1&web=1&e=1RMxUk", Icon: require('../assets/ico-desk-01.png') },
  { ItemType: "item", Sort: "8", Section: 'eventCoE', Title: "Sales Engagement", Link: "https://avanade.sharepoint.com/:p:/s/GlobalEventsFY23-EventsCOE/ERz9kX2JjspFq66Jt2XfIVkB8H_Y2AU36PKXGIl0XfcTfw?e=RvbDWN", Icon: require('../assets/ico-chart-03.png') },
  { ItemType: "section", Sort: "0", Section: "inPersonEvent", Title: "In Person Event Toolkit", Description: "This comprehensive collection of assets and resources provides everything needed to successfully plan and execute in-person events (tradeshows, roundtables, roadshows, etc.) ensuring a consistent and impactful client experience.", Options: JSON.stringify(inPersonOptions) },
  { ItemType: "item", Sort: "0", Section: 'inPersonEvent', Title: "Pre-event Checklist", Link: "https://avanade.sharepoint.com/:p:/r/sites/GlobalEventsFY23-EventsCOE/Shared%20Documents/Events%20COE/CoE%20Documents%20(Lis%20working)/To%20upload/Avanade%20event%20approval%20process%20and%20policies.pptx?d=w8b9d68d6ac8d4835b64d04d73e659354&csf=1&web=1&e=o8n3W4", Icon: require('../assets/ico-checklist-01.png') },
  { ItemType: "item", Sort: "1", Section: 'inPersonEvent', Title: "Project Plan", Link: "https://avanade.sharepoint.com/:x:/r/sites/MarketingGlobalEventsCoE/_layouts/15/Doc.aspx?sourcedoc=%7B72261EC6-D07D-4BC3-BA34-882DF7A42407%7D&file=Full%20Project%20Plan%20-%20Template.xlsx&action=default&mobileredirect=true", Icon: require('../assets/ico-timeline-01.png') },
  { ItemType: "item", Sort: "2", Section: 'inPersonEvent', Title: "Playbook", Link: "https://avanade.sharepoint.com/:p:/r/sites/MarketingGlobalEventsCoE/_layouts/15/Doc.aspx?sourcedoc=%7B0E3461B5-7F32-4279-B1D9-9C9A90BE2892%7D&file=Event%20Playbook.pptx&action=edit&mobileredirect=true", Icon: require('../assets/ico-folder-01.png') },
  { ItemType: "item", Sort: "3", Section: 'inPersonEvent', Title: "Onsite Look & Feel", Link: "https://avanade.sharepoint.com/:b:/s/CreativeLab/EdAd5Iv8LUFBr2ZuMauksVsBxcq7c294cvZdtb9_cNShtg?e=4qOs2d", Icon: require('../assets/ico-teacher-01.png') },
  { ItemType: "item", Sort: "4", Section: 'inPersonEvent', Title: "Client Invitations", Link: "https://avanade.sharepoint.com/:f:/r/sites/GlobalEventsFY23-EventsCOE/Shared%20Documents/Events%20COE/CoE%20Documents%20(Lis%20working)/To%20upload/Event%20(OFT)%20Invitation%20Templates?csf=1&web=1&e=FIJulE", Icon: require('../assets/ico-envelope-01.png') }, 
  { ItemType: "item", Sort: "5", Section: 'inPersonEvent', Title: "Internal Announcements", Link: "https://avanade.sharepoint.com/:f:/r/sites/GlobalEventsFY23-EventsCOE/Shared%20Documents/Events%20COE/CoE%20Documents%20(Lis%20working)/To%20upload/Internal%20Announcements?csf=1&web=1&e=1RMxUk", Icon: require('../assets/ico-megaphone-01.png') },
  { ItemType: "item", Sort: "6", Section: 'inPersonEvent', Title: "Onsite Tools", Link: "https://avanade.sharepoint.com/:p:/r/sites/MarketingGlobalEventsCoE/_layouts/15/Doc.aspx?sourcedoc=%7B65011498-4F4D-4421-A367-BB4F06134AA8%7D&file=Branded%20promotional%20items.pptx&action=edit&mobileredirect=true", Icon: require('../assets/ico-tools-01.png') },
  { ItemType: "item", Sort: "7", Section: 'inPersonEvent', Title: "Sales Engagement", Link: "https://avanade.sharepoint.com/:p:/s/GlobalEventsFY23-EventsCOE/ERz9kX2JjspFq66Jt2XfIVkB8H_Y2AU36PKXGIl0XfcTfw?e=RvbDWN", Icon: require('../assets/ico-chart-04.png') },
  { ItemType: "item", Sort: "8", Section: 'inPersonEvent', Title: "Post Event Process", Link: "https://avanade.sharepoint.com/:p:/r/sites/MarketingGlobalEventsCoE/_layouts/15/Doc.aspx?sourcedoc=%7B6A96E021-016F-4B14-B477-2B7886A12757%7D&file=Global%20Event%20Post-Event%20Process.pptx&action=edit&mobileredirect=true", Icon: require('../assets/ico-flowchart-01.png') },
  { ItemType: "item", Sort: "9", Section: 'inPersonEvent', Title: "Measuring Success", Link: "https://avanade.sharepoint.com/:p:/s/GlobalEventsFY23-EventsCOE/EbOcvb9Kch9KsfceUk6hw94B67tnCbo418LjhbYtzZGHxw?e=flU8JU", Icon: require('../assets/ico-chart-05.png') },
  { ItemType: "section",Sort: "0", Section: "virtualEvent", Title: "Virtual Event Toolkit", Description: "This comprehensive collection of assets and resources provides everything needed to successfully plan and execute webinars or virtual events, ensuring a consistent and impactful client experience.", Options: JSON.stringify(virtualOptions) },
  { ItemType: "item", Sort: "0", Section: 'virtualEvent', Title: "Content Dry Run", Link: "https://avanade.sharepoint.com/:p:/r/sites/GlobalEventsFY23/Shared%20Documents/General/Virtual%20Event%20CoE%202023/Global%20CoE%20Virtual%20Events%20Slides_Content%20dry%20run.pptx?d=w67e32eee359b41b2944546c47776cd41&csf=1&web=1&e=LXfUYe", Icon: require('../assets/ico-chart-02.png') },
  { ItemType: "item", Sort: "1", Section: 'virtualEvent', Title: "Content Owner", Link: "https://avanade.sharepoint.com/:p:/r/sites/GlobalEventsFY23/Shared%20Documents/General/Virtual%20Event%20CoE%202023/Content%20Owner_EMC.pptx?d=wcf9e259831944adebcd435b6460f03f6&csf=1&web=1&e=BNvYfJ", Icon: require('../assets/ico-chart-06.png') },
  { ItemType: "item", Sort: "2", Section: 'virtualEvent', Title: "Live Events", Link: "https://avanade.sharepoint.com/:p:/r/sites/GlobalEventsFY23-EventsCOE/Shared%20Documents/Events%20COE/CoE%20Documents%20(Lis%20working)/Teams%20Live%20-%20Event%20Guidance%202023.pptx?d=w03434534b0194953b973fc90cf64dbac&csf=1&web=1&e=Mubng4", Icon: require('../assets/ico-mic-01.png') },
  { ItemType: "item", Sort: "3", Section: 'virtualEvent', Title: "Pre Event", Link: "https://avanade.sharepoint.com/:p:/r/sites/GlobalEventsFY23/Shared%20Documents/General/Virtual%20Event%20CoE%202023/Global%20CoE%20Virtual%20Events%20Slides_Pre%20Event.pptx?d=w52b9ebf057ca4a19907a21a25ee23530&csf=1&web=1&e=IUz9dU", Icon: require('../assets/ico-calendar-02.png') },
  { ItemType: "item", Sort: "4", Section: 'virtualEvent', Title: "Roles & Responsibilities", Link: "https://avanade.sharepoint.com/:p:/r/sites/GlobalEventsFY23/Shared%20Documents/General/Virtual%20Event%20CoE%202023/Global%20CoE%20Virtual%20Events%20Slides_RR%20.pptx?d=w2a1c00779375441bad38f8fc415ac09d&csf=1&web=1&e=lMf6Sp", Icon: require('../assets/ico-chart-07.png') }, 
  { ItemType: "item", Sort: "5", Section: 'virtualEvent', Title: "Technical Dry Run", Link: "https://avanade.sharepoint.com/:p:/r/sites/GlobalEventsFY23/Shared%20Documents/General/Virtual%20Event%20CoE%202023/Global%20CoE%20Virtual%20Events%20Slides_Technical%20Dry%20Run.pptx?d=w10be0cd9aa0649b9b56285796e4039dc&csf=1&web=1&e=n0QQdH", Icon: require('../assets/ico-monitor-01.png') },
  { ItemType: "item", Sort: "6", Section: 'virtualEvent', Title: "Top 5 Learnings", Link: "https://avanade.sharepoint.com/:p:/r/sites/MarketingGlobalEventsCoE/_layouts/15/Doc.aspx?sourcedoc=%7BE53EA3F0-5DC4-4AC4-8C67-FD31C607F924%7D&file=Virtual%20Events%20_%20Top%205%20learnings.pptx&action=edit&mobileredirect=true", Icon: require('../assets/ico-grad-01.png') },
  { ItemType: "item", Sort: "7", Section: 'virtualEvent', Title: "Webinar Measurement", Link: "https://avanade.sharepoint.com/:p:/r/sites/GlobalEventsFY23/Shared%20Documents/General/Virtual%20Event%20CoE%202023/Global%20CoE%20Virtual%20Events%20Slides_Webinar%20Measurement.pptx?d=wf996cf83a9d8424d8b8de6a5aaedac67&csf=1&web=1&e=j0xrgc", Icon: require('../assets/ico-chart-08.png') },
  { ItemType: "item", Sort: "8", Section: 'virtualEvent', Title: "Webinar Process", Link: "https://avanade.sharepoint.com/:w:/r/teams/Marketing/_layouts/15/Doc.aspx?sourcedoc=%7BF88A2A0B-17D7-40A1-955F-163114F8BA0F%7D&file=MS%20Teams%20Webinar%20Event%20Request%20guidelines%20-%20.com%20and%20Marketo.docx&action=default&mobileredirect=true", Icon: require('../assets/ico-chart-09.png') },
  { ItemType: "item", Sort: "9", Section: 'virtualEvent', Title: "Webinar Survey", Link: "https://pages.avanade.com/event-experience-feedback-live.html", Icon: require('../assets/ico-chart-10.png') },
  { ItemType: "section", Sort: "0", Section: "moreResources", Title: "More Resources", Options: JSON.stringify(moreResources) },
  { ItemType: "item", Sort: "0", Section: 'moreResources', Title: "Global Programs and Campaigns", Link: "https://avanade.sharepoint.com/sites/GlobalCampaignHub", MenuIcon: icoglobe02 },
  { ItemType: "item", Sort: "1", Section: 'moreResources', Title: "Brand Site", Link: "https://avanade.sharepoint.com/sites/AvanadeBrand", MenuIcon: icocheckmark01 },
  { ItemType: "item", Sort: "2", Section: 'moreResources', Title: "Client Evidence CoE", Link: "https://avanade.sharepoint.com/sites/ClientEvidenceHub?xsdata=MDV8MDF8fDdmZjU4ZTQ5ZThlMjRlMGNlYTI1MDhkYjUwODhkOWYyfGNmMzYxNDFjZGRkNzQ1YTdiMDczMTExZjY2ZDBiMzBjfDB8MHw2MzgxOTIzMjA5MDU0MDcwMTN8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxNVFk0TXpZek5USTRPVFExTnpzeE5qZ3pOak0xTWpnNU5EVTNPekU1T21FNVlqa3dZVE16TFRNMU1XWXRORFExTXkwNFptTmtMVE0xT1ROaE5EVTBZelU0TWw5aU56azVZV1l4T1MxaE5UWXpMVFEzTXpVdFlUTXlaaTAwWWpobFlqSmxZall4TjJSQWRXNXhMbWRpYkM1emNHRmpaWE09fDEwNzAwOGU3YmZhNzRiNDhlYTI1MDhkYjUwODhkOWYyfDI3NWIwNjJiMGRjNTQ5MjY4ZjI4OTgwMWJiNmM3ZjY4&sdata=L3B4NTYwQnVXRnRoRVdla21IRS9uOGE5SmlBOUFJOC82a2hVUTRXdUVaUT0%3D&ovuser=cf36141c-ddd7-45a7-b073-111f66d0b30c%2Celisabeth.cannon%40avanade.com&OR=Teams-HL&CT=1686928819008&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMzA1MTQwMTgyMyIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D", MenuIcon: icopencil01a },
  { ItemType: "item", Sort: "3", Section: 'moreResources', Title: "Social Media CoE", Link: "https://avanade.sharepoint.com/sites/SocialMediaCoE", MenuIcon: icoheart01 },
  { ItemType: "section", Sort: "0", Section: "ourEvents", Title: "Our Events", Description: "Explore snapshots of Avanade live and in-person throughout some of our global events throughout FY23. Want to highlight/feature a photo of Avanade from a recent event? Contact us at <a href=\"mailto:globalevents@avanade.com\">globalevents@avanade.com</a>", Options: JSON.stringify(ourEvents) },
  { ItemType: "item", Sort: "0", Section: 'ourEvents', Description: "", Image: require('../assets/gallery/1.jpg') },
  { ItemType: "item", Sort: "1", Section: 'ourEvents', Description: "", Image: require('../assets/gallery/2.jpg') },
  { ItemType: "item", Sort: "2", Section: 'ourEvents', Description: "", Image: require('../assets/gallery/3.jpg') },
  { ItemType: "item", Sort: "3", Section: 'ourEvents', Description: "", Image: require('../assets/gallery/4.jpg') },
  { ItemType: "item", Sort: "4", Section: 'ourEvents', Description: "", Image: require('../assets/gallery/5.jpg') },
  { ItemType: "item", Sort: "5", Section: 'ourEvents', Description: "", Image: require('../assets/gallery/6.jpg') },
  { ItemType: "item", Sort: "6", Section: 'ourEvents', Description: "", Image: require('../assets/gallery/7.jpg') },
  { ItemType: "item", Sort: "7", Section: 'ourEvents', Description: "", Image: require('../assets/gallery/8.jpg') },
  { ItemType: "item", Sort: "8", Section: 'ourEvents', Description: "", Image: require('../assets/gallery/9.jpg') },
  { ItemType: "item", Sort: "9", Section: 'ourEvents', Description: "", Image: require('../assets/gallery/10.jpg') },
  { ItemType: "item", Sort: "10", Section: 'ourEvents', Description: "", Image: require('../assets/gallery/11.jpg') },
  { ItemType: "item", Sort: "11", Section: 'ourEvents', Description: "", Image: require('../assets/gallery/12.jpg') },
  { ItemType: "item", Sort: "12", Section: 'ourEvents', Description: "", Image: require('../assets/gallery/13.jpg') },
  { ItemType: "item", Sort: "13", Section: 'ourEvents', Description: "", Image: require('../assets/gallery/14.jpg') },
  { ItemType: "item", Sort: "14", Section: 'ourEvents', Description: "", Image: require('../assets/gallery/15.jpg') },
]

export interface AppStore extends AppStoreState {
    getItem: (section: string, type?: string) => Partial<ISPListItem> | undefined;
    getItems: (section: string, type?: string) => Partial<ISPListItem>[];
    isEditor: boolean;
    setSelectedItem: (item: Partial<ISPListItem>) => void;
}

export const useApp = (): AppStore => {
  const isEditor = false;

  const getItems = (section: string, type?: string): Partial<ISPListItem>[] => {
    if (!mockItems) return [];
    const t = type || 'item';
    return mockItems
      .filter((item) => item.Section === section && item.ItemType === t)
      .sort((a, b) => parseInt(a.Sort) - parseInt(b.Sort))
  };

  const getItem = (section: string, type?: string): Partial<ISPListItem> | undefined => {
    if (!mockItems) return undefined;
    const items = mockItems.filter((item) => {
        if (type) {
            return item.Section === section && item.ItemType === type
        }
        return item.Section === section
    })
    const item = items[0];
    let Options = {};
    if (item && item.Options) {
      try {
        Options = JSON.parse(item.Options);
      } catch (err) {
        console.log(err, item.Options);
      }
      return {
        ...item,
        Options,
      };
    }
    return items[0]
  };

  const s = {
    getItem,
    getItems,
    isEditor,
    setSelectedItem: () => {},
  };

  // console.log(s);

  return s;
};

// const container = createContainer(AppProvider);

// export const { Provider, useContainer } = container;

// export const useApp = useContainer;

// export default container;
