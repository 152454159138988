/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import styles from './SidebarMenu.module.scss';
import { useApp } from '../../state/app';

interface SidebarMenuProps {
    name: string;
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({ name }) => {
    const { getItem, getItems, setSelectedItem, isEditor } = useApp();
    const section = getItem(name, "section");
    if (!section) return null;
    const items = getItems(name) || [];
    const options = section.Options;
    const color = options.iconColor;
    return (
        <section>
            <h2 className={styles.heading}>{section.Title}</h2>
            <div className={styles.links}>
                {items.map((item, index) => {
                    const { Title, Link, MenuIcon } = item;
                    return (
                    <div className={styles.link} key={`link_${index}`}>
                        {MenuIcon 
                            ? (<img src={MenuIcon} />)
                            : (<div className={styles.number} style={{ backgroundColor: color || null }}>{renderNumber(index)}</div>)
                        }
                        <a href={Link}>
                            {Title}
                        </a>
                        {isEditor && <button onClick={() => setSelectedItem(item)} className={styles.editButton}>Edit</button>}
                    </div>
                    )
                })}
            </div>
        </section>
    );
}

function renderNumber(num: number): string {
    const v = num + 1;
    if (v < 10) {
        return `0${v}`;
    }
    return (v).toString();
}

export default SidebarMenu;