//@ts-nocheck
import * as React from 'react';
import * as ReactModal from 'react-modal';
import { useApp } from '../state/app';
import styles from './EditItemForm.module.scss';
import { Formik, Form, Field, useField } from 'formik';
import * as Yup from 'yup';

import type {
    ISPListItem,
  } from '../types';

interface ISPListItemFormValues extends Partial<ISPListItem> {
    File: File;
}

const SignupSchema = Yup.object().shape({
    Description: Yup.string().nullable(),
    Sort: Yup.string().required(),
    Image: Yup.string().nullable(),
});

const EditItemForm = ({ item, onClose }: { item: Partial<ISPListItem>, onClose: () => void }) => {
    const { createListItem, updateListItem, deleteListItem, uploadFile } = useApp();

    const handleDelete = () => {
        deleteListItem(item)
            .then(() => {
                onClose();
            })
            .catch(console.log)
    }

    return (
        <Formik
            enableReinitialize
            initialValues={item}
            validationSchema={SignupSchema}
            onSubmit={(values: ISPListItemFormValues) => {
                const op = values.Id ? updateListItem : createListItem;
                let { File, ...rest } = values;
                if (!values.Id) {
                    rest = {
                        ...rest,
                        Section: 'ourEvents',
                        ItemType: 'item',
                    }
                }
                if (File) {
                    uploadFile(File)
                    .then((imageUrl: string) => op({
                        ...rest,
                        Image: imageUrl 
                    }))
                    .then(() => onClose())
                    .catch(console.log);
                } else {
                    op(rest)
                    .then(() => onClose())
                    .catch(console.log);
                }
                
            }}
        >
            <Form className={styles.galleryForm}>
                <div className={styles.galleryFormItems}>
                    <RenderField isFullWidth name={'Description'} />
                </div>
                <div className={styles.galleryFormItems}>
                    <RenderField isFullWidth name={'Image'} />
                </div>
                <div className={styles.galleryFormItems}>
                    <RenderField isFullWidth name={'Sort'} />
                </div>
                <div className={styles.galleryFormItems}>
                    <RenderFileInput name="File" />
                </div>
                <div className={styles.formActions}>
                    <button className={styles.submitButton} onClick={() => handleDelete()}>Delete</button>
                    <button className={styles.submitButton} type="submit">Save</button>
                </div>
            </Form>
        </Formik>
    )
}

const EditGalleryModal = () => {
    const [selectedItem, setSelectedItem] = React.useState<Partial<ISPListItem>>();
    const { 
        editGallery, 
        setEditGallery,
        getItems,
    } = useApp();

    const handleClose = () => {
        setEditGallery(false);
    }

    const galleryItems = getItems('ourEvents');

    const handleAddNew = () => {
        setSelectedItem({
            Title: '',
            Description: '',
            Image: '',
            Sort: '',
        })
    }

    React.useEffect(() => {
        if (editGallery === false) setSelectedItem(undefined)
    }, [editGallery])
    
    return (
        <ReactModal
            isOpen={editGallery}
            contentLabel="onRequestClose Example"
            onRequestClose={() => handleClose()}
            className={styles.ImageGalleryModal}
            overlayClassName={styles.Overlay}
        >
            <div className={styles.galleryItems}>
                {galleryItems.map(item => (
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img 
                        onClick={() => setSelectedItem(item)}
                        src={item.Image} 
                        key={item.Id} 
                        style={{ width: '100px', height: 'auto', margin: '0.5rem', cursor: 'pointer' }} 
                    />
                ))}
            </div>
            {selectedItem && <EditItemForm item={selectedItem} onClose={handleClose} />}
            <div className={styles.formActions}>
            <button className={styles.closeButton} onClick={() => handleClose()}>Close</button>
            <button className={styles.closeButton} onClick={() => handleAddNew()}>Add New</button>
            </div>
        </ReactModal>
    )
}

function RenderField ({ name, isFullWidth }: {name: keyof ISPListItem, isFullWidth?: boolean }): React.ReactElement {
    const [, meta] = useField(name);
    return (
        <div className={isFullWidth ? styles.fullWidth : styles.halfWidth}>
            <label className={styles.largeLabel}>{name}:</label>
            <Field name={name} as={name === 'Description' ? 'textarea' : 'input'} />
            {meta.error && meta.touched ? (
                <div>{meta.error}</div>
            ) : null}
        </div>
    )
}

function RenderFileInput ({ name, isFullWidth }: {name: string, isFullWidth?: boolean }): React.ReactElement {
    const [, meta, helpers] = useField<File>(name);
    return (
        <div className={isFullWidth ? styles.fullWidth : styles.halfWidth}>
            <label className={styles.largeLabel}>{name}:</label>
            <input type="file" name="file" onChange={(event) => {
                const file = event.currentTarget.files[0];
                helpers.setValue(file)
                    .then(console.log)
                    .catch(console.log)
            }} />
            {meta.error && meta.touched ? (
                <div>{meta.error}</div>
            ) : null}
        </div>
    )
}



export default EditGalleryModal;