/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import styles from './IconGrid.module.scss';
import { useApp } from '../../state/app';

interface IconGridProps {
    name: string;
}

const IconGrid: React.FC<IconGridProps> = ({ name }) => {
    const { getItems, getItem } = useApp();
    const items = getItems(name) || [];
    const section = getItem(name, "section");
    if (!section) return null;
    const options = section.Options;
    const color = options.borderColor;
    return (
        <section className={styles.linkContainer}>
            <div className={styles.links}>
                {items.map(({ Title, Link, Icon }, index) => {
                    return (
                    <div className={styles.link} key={`link_${index}`} style={{ borderColor: color }}>
                        <img src={Icon} />
                        <a href={Link}>
                            {Title}
                        </a>
                    </div>
                    )
                })}
            </div>
        </section>
    );
}

export default IconGrid;