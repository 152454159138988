//@ts-nocheck
import * as React from 'react';
import * as ReactModal from 'react-modal';
import { useApp } from '../state/app';
import styles from './EditItemForm.module.scss';
import { Formik, Form, Field, FormikHelpers, useField } from 'formik';
import * as Yup from 'yup';

import type { ISPListItem } from '../types';

interface ItemWithOptions extends Partial<ISPListItem> {
    iconColor?: string;
    borderColor?: string;
    gradient?: { 
        start: string, 
        end: string 
    }
}

const SignupSchema = Yup.object().shape({
    Sort: Yup.string().required(),
    Title: Yup.string().required(),
    Section: Yup.string().nullable(),
    Icon: Yup.string().nullable(),
    MenuIcon: Yup.string().nullable(),
    Link: Yup.string().nullable(),
});

const EditItemForm: React.FC<any> = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const { selectedItem, setSelectedItem, updateListItem } = useApp();
    
    React.useEffect(() => {
        console.log({
            selectedItem,
            isOpen,
        })
        if (selectedItem) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem]);

    const handleClose = () => {
        setSelectedItem(undefined);
    }

    const formFields = selectedItem?.ItemType === 'section'
        ? [
            'Title',
            'Description',
            'iconColor',
            'borderColor',
            'gradient.start',
            'gradient.end'
        ] : [
            'Title',
            'Description',
            'Link',
            'Icon',
            'MenuIcon',
            'Sort',
        ];

    const getInitialValues = (): ItemWithOptions => {
        if (selectedItem?.ItemType === 'section') { 
            const options = selectedItem.Options || {
                iconColor: '',
                borderColor: '',
                gradient: {
                    start: '',
                    end: '',
                },
            }
            // try {
            //     options = JSON.parse(selectedItem.Options);
            // } catch (err) {
            //     console.log(err);
            // }
            return {
                ...selectedItem,
                iconColor: options.iconColor,
                borderColor: options.borderColor,
                gradient: options.gradient,
            }
        } else {
            return selectedItem;
        }
    }
    return (
        <>
        <ReactModal
            isOpen={isOpen}
            contentLabel="onRequestClose Example"
            onRequestClose={() => handleClose()}
            className={styles.Modal}
            overlayClassName={styles.Overlay}
        >
            <Formik
            initialValues={getInitialValues()}
            validationSchema={SignupSchema}
            onSubmit={(values: ItemWithOptions, helpers: FormikHelpers<ItemWithOptions>) => {
                const options = {
                    iconColor: values.iconColor || '',
                    borderColor: values.borderColor || '',
                    gradient: {
                        start: values.gradient?.start || '',
                        end: values.gradient?.end || '',
                    }
                }
                updateListItem({
                    ...values,
                    Options: JSON.stringify(options),
                }).then(() => handleClose()).catch(console.log)
            }}
            >
                <Form className={styles.form}>
                    {formFields.map((field: keyof ItemWithOptions) => (
                        <RenderField key={field} name={field} />
                    ))}
                    <div className={styles.formActions}>
                        <button className={styles.closeButton} onClick={() => handleClose()}>Close</button>
                        <button className={styles.submitButton} type="submit">Submit</button>
                    </div>
                </Form>
            </Formik>
        </ReactModal>
     </>
    )
}

function RenderField ({ name }: {name: keyof ItemWithOptions}): React.ReactElement {
    const [, meta] = useField<Partial<ItemWithOptions>>(name);
    return (
        <div className={styles.fullWidth}>
            <label className={styles.largeLabel}>{name}:</label>
            <Field name={name} as={name === 'Description' ? 'textarea' : 'input'} />
            {meta.error && meta.touched ? (
                <div>{meta.error}</div>
            ) : null}
        </div>
    )
}

// function RenderOptionsField ({ name }: {name: keyof ItemWithOptions }): React.ReactElement {
//     const [field, meta] = useField(name);
//     let options = {}
    
//     try {
//         options = JSON.parse(field.value);
//     } catch (err) {
//         console.log(err);
//     }

//     return (
//         <div className={styles.fullWidth}>
//             <label className={styles.largeLabel}>{name}:</label>
//             <Field name={name} as={name === 'Description' ? 'textarea' : 'input'} />
//             {meta.error && meta.touched ? (
//                 <div>{meta.error}</div>
//             ) : null}
//         </div>
//     )
// }

export default EditItemForm;